'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function (window) {
    'use strict';

    if (!Array.indexOf) {
        Array.prototype.indexOf = function (obj, start) {
            for (var i = start || 0; i < this.length; i++) {
                if (this[i] == obj) {
                    return i;
                }
            }
            return -1;
        };
    }
    var $ = window.lq = {
        qs: function qs(s) {
            return document.querySelectorAll(s);
        },
        domReady: function domReady(fn) {
            if (document.addEventListener) {
                document.addEventListener('DOMContentLoaded', fn);
            } else {
                var readyStateCheckInterval = setInterval(function () {
                    if (document.readyState === "complete") {
                        clearInterval(readyStateCheckInterval);
                        fn();
                    }
                }, 5);
            }
        },
        on: function on(el, eventName, callback, context) {
            if (el.addEventListener) {
                el.addEventListener(eventName, function (e) {
                    callback.call(context, e);
                }, false);
            } else if (el.attachEvent) {
                el.attachEvent('on' + eventName, function (e) {
                    callback.call(context, e);
                });
            }
        },
        each: function each(array, fn) {
            for (var i = 0, l = array.length; i < l; i++) {
                fn(array[i], i);
            }
        },
        extend: function extend(defaults, options) {
            var extended = {},
                prop;
            for (prop in defaults) {
                if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
                    extended[prop] = defaults[prop];
                }
            }
            for (prop in options) {
                if (Object.prototype.hasOwnProperty.call(options, prop)) {
                    extended[prop] = options[prop];
                }
            }
            return extended;
        },
        serialize: function serialize(form) {
            var obj = {};
            for (var i = 0, l = form.length; i < l; i++) {
                obj[form[i].getAttribute('name')] = form[i].value;
            }
            return obj;
        },
        bi: function bi(id) {
            return document.getElementById(id);
        },
        bt: function bt(tagName) {
            return document.getElementsByTagName(tagName);
        },
        urlGET: function urlGET(name) {
            if (name = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)').exec(location.search)) ;
            return name === null ? '' : decodeURIComponent(name[1]);
        },
        objectToGet: function objectToGet(object) {
            var GETString = '';
            var encode = '';
            for (var key in object) {
                encode = encodeURIComponent(object[key]);
                GETString += '&' + key + '=' + encode;
            }
            return GETString;
        },
        JSONP: function JSONP(url) {
            var script = document.createElement('script');
            script.async = true;
            script.setAttribute('src', url);

            document.body.appendChild(script);
        },
        getCookie: function getCookie(name) {
            var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }
    };

    var Mostpartner = function Mostpartner(params) {
        var _this = this;

        this.state = {
            inited: true
        };

        this.conf = $.extend({
            landingName: 'Land Name',
            location: $.getCookie('redirectHost') + document.location.pathname
        }, params);

        $.domReady(function () {
            _this.initDOM.call(_this);
            _this.setFavicon.call(_this);
        });


        return this;
    };

    Mostpartner.prototype.initDOM = function () {
        this.sendCookie();
    };
;
    Mostpartner.prototype.setFavicon = function () {
        if(!document.querySelector('link[rel="icon"]')) {
            var icon = document.createElement('link');
            icon.rel = 'icon';
            icon.type = 'image/x-icon';
            icon.href = '/cdn/images/favicon.ico';
            document.head.appendChild(icon);
        }
    };

    Mostpartner.prototype.sendCookie = function () {
        $.JSONP(location.protocol + "//" + $.urlGET('h') + "/transit-view?cid=" + $.urlGET('cid') + "&callback=lMostpartner.changeLinksUrl");
    };

    Mostpartner.prototype.changeLinksUrl = function (data) {
        var $links = $.bt('a');

        for (var i = $links.length - 1; i >= 0; i--) {
            if (_typeof($links[i].attributes.href) === 'object') {
                $links[i].attributes.href.value = data.redirectUrl;
            }
        }
    };

    window.lMostpartner = new Mostpartner();
})(window);
//test